<template>
  <form>
    <div class="form-group col-100">
      <div class="form-control col-50 mr-1">
        <label for="ryczVal">Stawka podatku</label>
        <input
          type="number"
          name="ryczVal"
          id="ryczVal"
          v-model="ryczVal"
          @change="countAfterPlusVat"
        />
      </div>
      <div class="form-control col-50">
        <label for="vatVal">Stawka VAT</label>
        <input
          type="number"
          name="vatVal"
          id="vatVal"
          v-model="vatVal"
          @change="countAfterPlusVat"
        />
      </div>
    </div>
    <div class="form-control col-100">
      <label for="beforeTax">Przed opodatkowaniem</label>
      <input
        type="number"
        name="beforeTax"
        id="beforeTax"
        @input="countAfterPlusVat"
        v-model="beforeTax"
      />
    </div>
    <div class="form-control col-100">
      <label for="afterTax">Kwota netto</label>
      <input
        type="number"
        name="afterTax"
        id="afterTax"
        v-model="afterTax"
        @input="countBeforePlusVat"
      />
    </div>
    <div class="form-control col-100">
      <label for="plusVat">Kwota brutto</label>
      <input
        type="number"
        name="plusVat"
        id="plusVat"
        v-model="plusVat"
        @input="countBeforeAfter"
      />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    initialValue: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      beforeTax: 0.0,
      afterTax: 0.0,
      plusVat: 0.0,
      vatVal: 23,
      ryczVal: 8.5,
    };
  },
  watch: {
    initialValue(newVal) {
      this.beforeTax = newVal;
      this.countAfterPlusVat();
    },
  },
  methods: {
    countAfterPlusVat() {
      this.afterTax = ((this.beforeTax * 100) / (100 - this.ryczVal)).toFixed(
        2
      );
      this.plusVat = (
        +this.afterTax +
        this.afterTax * (this.vatVal / 100)
      ).toFixed(2);
      this.$emit("tax-val", this.ryczVal);
    },
    countBeforePlusVat() {
      this.beforeTax = (
        this.afterTax -
        this.afterTax * (this.ryczVal / 100)
      ).toFixed(2);
      this.plusVat = (
        +this.afterTax +
        (this.afterTax * this.vatVal) / 100
      ).toFixed(2);
    },
    countBeforeAfter() {
      this.afterTax = ((this.plusVat * 100) / (100 + this.vatVal)).toFixed(2);
      this.beforeTax = (
        +this.afterTax -
        (this.afterTax * this.ryczVal) / 100
      ).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../_base.scss";
header {
  h1,
  h4 {
    margin: 0;
  }
}
.form-group {
  justify-content: space-between;
}
</style>